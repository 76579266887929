import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { HeartFilled } from "@ant-design/icons";
import { observer, inject } from "mobx-react";
import sideImage from "../../assets/images/background.png";
import Login from "./Login";

@inject("userStore", "tokenStore", "sectionsStore")
@observer
class LoginManager extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({});

    this.state = this.initialState();
  }

  render() {
    const { tokenStore, to } = this.props;
    if (`${tokenStore.value}`.trim() !== "") {
      return to;
    }
    return (
      <Row
        style={{ position: "absolute", height: "100%", width: "100%" }}
        type="flex"
        align="middle"
      >
        <Col
          style={{
            height: "100%",
            backgroundImage: `url('${sideImage}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "1px 3px 9px #dde4e7",
          }}
          span={15}
        >
          <Row
            style={{ position: "absolute", height: "100%", width: "100%" }}
            type="flex"
            align="middle"
          >
            <Col span={24}>
              {/* <img
                src={sideImage}
                style={{
                  width: '90%',
                  margin: '0 auto',
                  left: 0,
                  right: 0,
                  // top: '25%',
                }}
              /> */}
            </Col>
            {/* <Col span={24} style={{ marginTop: '50%' }}>
              <Typography.Paragraph
                align="center"
                type="secondary"
              >
                <span>Developed with </span>
                <HeartFilled style={{ color: 'red' }} />
                {' '}
                By&nbsp;
                <a href="https://lucid-source.com" title="Lucid Source">
                  Lucid Source
                </a>
              </Typography.Paragraph>
            </Col> */}
          </Row>
        </Col>
        <Col span={9} offset={0}>
          <Row>
            <Col span={24}>
              <Typography.Title
                align="center"
                color="primary"
                style={{ color: "#1890ff", letterSpacing: "1.5px" }}
              >
                Kavin Group
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Card bordered={false}>
                <Login />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default LoginManager;
