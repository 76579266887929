import React, { Suspense } from "react";

import { observer, inject } from "mobx-react";
import { Layout, Menu, Button, Col, Popover, Avatar, List } from "antd";
import {
  HeartFilled,
  MenuOutlined,
  ProjectFilled,
  FileImageFilled,
  FileTextFilled,
  ContactsFilled,
} from "@ant-design/icons";

import { Switch, Route, Link, withRouter } from "react-router-dom";
import Loading from "../basic/Loading";
import routes from "./dashboardRoutes";
import largeLogo from "../../assets/images/logo.svg";
import smallLogo from "../../assets/images/logo2.svg";

import "./dashboard.css";

const { Header, Content, Footer, Sider } = Layout;

@observer
@inject("userStore", "tokenStore", "sectionsStore")
class Dashboard extends React.Component {
  constructor() {
    super();
    this.initialState = () => ({
      collapsed: false,
      expandedWithToggler: true,
      imageId: "",
    });
    this.state = this.initialState();
    this.onCollapse = (collapsed) => {
      this.setState({ collapsed });
    };
    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
        expandedWithToggler: true,
      }));
    };
    this.expandSidebar = () => {
      const { collapsed } = this.state;
      if (collapsed) {
        this.setState({ collapsed: false, expandedWithToggler: false });
      }
    };
    this.collapseSidebar = () => {
      const { expandedWithToggler } = this.state;
      if (!expandedWithToggler) this.setState({ collapsed: true });
    };
    this.logout = () => {
      Promise.all([
        // eslint-disable-next-line react/destructuring-assignment
        this.props.userStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.tokenStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.sectionsStore.clear(),
      ]).then(() => {});
    };
  }

  render() {
    const { collapsed, imageId } = this.state;
    const { location } = this.props;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <style>
          {`
          .ant-menu-item-selected{
            background-color: #1f2533 !important;
          }
        `}
        </style>
        <Sider
          theme="dark"
          onMouseEnter={this.expandSidebar}
          onMouseLeave={this.collapseSidebar}
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          style={{
            height: "100vh",
            left: 0,
            position: "fixed",
            overflow: "auto",
            background: "#2d3546",
          }}
          width={240}
        >
          <div
            className="logo"
            style={{
              minHeight: 70,
              textAlign: "center",
              paddingTop: 20,
              background: "#1f2532",
            }}
          >
            {collapsed ? (
              <img
                alt="lucid-logo"
                style={{ width: 45, marginBottom: 5 }}
                src={smallLogo}
              />
            ) : (
              <img alt="lucid-logo" style={{ width: 140 }} src={largeLogo} />
            )}
          </div>
          <Menu
            theme="dark"
            selectedKeys={[location.pathname]}
            mode="inline"
            style={{ background: "#2d3646", marginTop: 35 }}
          >
            {/* <Menu.Item key="/inventories">
              <ApartmentOutlined />
              <span>Inventories</span>
              <Link to="/inventories" />
            </Menu.Item> */}
            <Menu.Item key="/projects">
              <ProjectFilled />
              <span>Projects</span>
              <Link to="/projects" />
            </Menu.Item>
            <Menu.Item key="/statistics">
              <FileTextFilled />
              <span>Statistics</span>
              <Link to="/statistics" />
            </Menu.Item>
            {/* <Menu.Item key="/gallery">
              <FileImageFilled />
              <span>Gallery</span>
              <Link to="/gallery" />
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            transition: "all 0.2s",
            marginLeft: collapsed ? 80 : 240,
          }}
        >
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: "inherit" }}
          >
            <Col span={1} style={{ textAlign: "center" }}>
              <Button
                type="link"
                icon={
                  <MenuOutlined style={{ fontSize: 15, color: "#1f2532" }} />
                }
                onClick={this.toggleSidebar}
              />
            </Col>
            <Col offset={21} span={2}>
              <Popover
                content={
                  <List>
                    <List.Item>
                      <a href="# " onClick={this.logout}>
                        Logout
                      </a>
                    </List.Item>
                  </List>
                }
                trigger="hover"
                // visible={visible}
                // onVisibleChange={handleVisibleChange}
                arrowPointAtCenter
                placement="bottomLeft"
              >
                <Avatar
                  src={`https://www.pngkey.com/png/full/114-1149878_setting-user-avatar-in-specific-size-without-breaking.png`}
                />
              </Popover>
            </Col>
          </Header>
          <Content style={{ margin: "16px 16px", marginBottom: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 500 }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={`${route.path}`}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>
            <span>Developed with </span>
            <HeartFilled />
            By&nbsp;
            <a href="https://lucid-source.com" title="Lucid Source">
              Lucid Source
            </a>
          </Footer> */}
        </Layout>
      </Layout>
    );
  }
}

export default withRouter((props) => <Dashboard {...props} />);
